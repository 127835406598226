import { useState } from 'react';
import './style.css';
import Inventory from '../../components/Inventory';
import Nav from '../../components/Nav';
import LocationNav from '../../components/LocationNav';
import Wolfal from '../Wolfal';
import Candem from '../Candem';
import Culper from '../Culper';
import MainLoading from '../../components/MainLoading';
import Achievements from '../../components/Achievements';
import Settings from '../../components/Settings';

export default function Home() {
  // const [playerInventory, setPlayerInventory] = useState(false);
  const [locationNavOpen, setLocationNavOpen] = useState(false);
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [achievementsOpen, setAchievementsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('wolfal');

  // useEffect(()=> {

  // }, [])

  const toggleLocationNav = () => {
    setLocationNavOpen((locationNavOpen) => !locationNavOpen);
  };

  const renderRegionContent = () => {
    switch (activeTab) {
      case 'wolfal':
        return <Wolfal />;
      case 'candem':
        return <Candem />;
      case 'culper':
        return <Culper />;
      default:
        return <div>Wolfal Harbor</div>;
    }
  };

  const toggleInventory = () => {
    setInventoryOpen((inventoryOpen) => !inventoryOpen);
  };

  const toggleAchievements = () => {
    setAchievementsOpen((achievementsOpen) => !achievementsOpen);
  };

  const toggleSettings = () => {
    setSettingsOpen((settingsOpen) => !settingsOpen);
  };

  return (
    <>
      <MainLoading />
      <Inventory invIsOpen={inventoryOpen} toggleInventory={toggleInventory} />
      <Achievements achIsOpen={achievementsOpen} toggleAchievements={toggleAchievements} />
      <Settings settingsIsOpen={settingsOpen} toggleSettings={toggleSettings} />
      <Nav
        toggleNav={toggleLocationNav}
        toggleInventory={toggleInventory}
        toggleAchievements={toggleAchievements}
        toggleSettings={toggleSettings}
      />
      <div className="mainContent">
        <LocationNav isOpen={locationNavOpen} activeTab={activeTab} setActiveTab={setActiveTab} />
        {renderRegionContent()}
      </div>
    </>
  );
}
