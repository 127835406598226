import './style.css';

interface iRegionPageHeader {
  regionTitle: string;
  subregions: string[];
  activeRegionContent: string;
  setActiveRegionContent: CallableFunction;
}

export default function RegionPageHeader({ regionTitle, subregions, activeRegionContent, setActiveRegionContent }: iRegionPageHeader) {
  return (
    <div className="regionContainer">
      <h1 className="regionTitle">{regionTitle}</h1>
      <div className="subregions">
        {subregions.map((region, index) => (
          <div className={region === activeRegionContent ? "subregion active-subregion" : "subregion"} key={index} onClick={() => setActiveRegionContent(region)}>
            {region}
          </div>
        ))}
      </div>
    </div>
  );
}