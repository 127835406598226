import { useState } from 'react';
import './style.css';
import RegionPageHeader from '../../components/RegionPageHeader';
import PinnedInventory from '../../components/PinnedInventory';

export default function Candem() {
  const [activeCandemContent, setActiveCandemContent] = useState('one');

  const renderCandemContent = () => {
    switch (activeCandemContent) {
      case 'one':
        return <div>one</div>;
      case 'two':
        return <div>two</div>;
      case 'three':
        return <div>three</div>;
      default:
        return <div>two</div>;
    }
  };

  return (
    <div>
      <RegionPageHeader
        regionTitle={'Candem'}
        subregions={['one', 'two', 'three']}
        activeRegionContent={activeCandemContent}
        setActiveRegionContent={setActiveCandemContent}
      />
      <PinnedInventory />
      <div className="activeRegionContent">{renderCandemContent()}</div>
    </div>
  );
}
