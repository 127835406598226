import './style.css';

export default function Scrapyard() {
  return (
    <div className="activitySelection">
      <div className="activityContainer"></div>
      <div className="activityContainer"></div>
      <div className="activityContainer"></div>
      <div className="activityContainer"></div>
      <div className="activityContainer"></div>
    </div>
  );
}
