import { useState } from 'react';
import './style.css';
import RegionPageHeader from '../../components/RegionPageHeader';
import PinnedInventory from '../../components/PinnedInventory';
import WolfalScrapyard from '../WolfalScrapyard';
import WolfalMarket from '../WolfalMarket';
import WolfalFishing from '../WolfalFishing';

export default function Wolfal() {
  const [activeWolfalContent, setActiveWolfalContent] = useState('Scrapyard');

  const renderWolfalContent = () => {
    switch (activeWolfalContent) {
      case 'Scrapyard':
        return <WolfalScrapyard />;
      case 'Market':
        return <WolfalMarket />;
      case 'Fishing':
        return <WolfalFishing />;
      default:
        return <WolfalScrapyard />;
    }
  };

  return (
    <div>
      <RegionPageHeader
        regionTitle={'Wolfal Harbor'}
        subregions={['Scrapyard', 'Market', 'Fishing']}
        activeRegionContent={activeWolfalContent}
        setActiveRegionContent={setActiveWolfalContent}
      />
      <PinnedInventory />
      <div className="activeRegionContent">{renderWolfalContent()}</div>
    </div>
  );
}
