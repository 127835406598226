import './style.css';
import { motion } from 'framer-motion';
import RegionNav from '../RegionNav';

interface iLocationNav {
  isOpen: boolean;
  activeTab: string;
  setActiveTab: CallableFunction;
}

export default function LocationNav({ isOpen, activeTab, setActiveTab }: iLocationNav) {

  return isOpen ? (
    <motion.div
      initial={{ width: 0 }}
      animate={{
        width: 200,
        transitionEnd: {
          width: 200,
          visibility: 'visible',
        },
      }}
      transition={{
        duration: 0.1,
      }}
    >
      <div className="locationNavContainer">
        <RegionNav name={'Wolfal Harbor'} idVal={'wolfal'} activeTab={activeTab} selectedRegion={setActiveTab} />
        <RegionNav name={'Candem'} idVal={'candem'} activeTab={activeTab} selectedRegion={setActiveTab} />
        <RegionNav name={'Culper City'} idVal={'culper'} activeTab={activeTab} selectedRegion={setActiveTab} />
      </div>
    </motion.div>
  ) : (
    <motion.div
      initial={{ width: 200 }}
      animate={{
        width: 0,
        transitionEnd: {
          width: 0,
          visibility: 'hidden',
        },
      }}
      transition={{
        duration: 0.1,
      }}
    >
      <div className="locationNavContainer"></div>
    </motion.div>
  );
}
