import './style.css';
import { motion } from 'framer-motion';
import { X } from 'react-feather';
import OutsideClickHandler from 'react-outside-click-handler';

interface iInventory {
  invIsOpen: boolean;
  toggleInventory: CallableFunction;
}

export default function Inventory({ invIsOpen, toggleInventory }: iInventory) {
  return invIsOpen ? (
    <OutsideClickHandler onOutsideClick={() => toggleInventory()}>
      <motion.div
        className="inventory"
        initial={{ height: '0%' }}
        animate={{
          height: '80%',
          transitionEnd: {
            height: '80%',
            visibility: 'visible',
          },
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1.5,
          }}
        >
          <div className="inventoryNav">
            <X className="xIcon" size={26} onClick={() => toggleInventory()} />
          </div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
          <div>tdsafdsaf</div>
        </motion.div>
      </motion.div>
    </OutsideClickHandler>
  ) : (
    <motion.div
      className="inventory"
      initial={{ height: '80%' }}
      animate={{
        height: '0%',
        transitionEnd: {
          height: '0%',
          visibility: 'hidden',
        },
      }}
      transition={{
        duration: 0.3,
      }}
    />
  );
}
