import './style.css';
// import { motion } from 'framer-motion';
import { Menu, Award, FileText, Settings } from 'react-feather';

interface iNav {
  toggleNav: CallableFunction;
  toggleInventory: CallableFunction;
  toggleAchievements: CallableFunction;
  toggleSettings: CallableFunction;
}

export default function Nav({ toggleNav, toggleInventory, toggleAchievements, toggleSettings }: iNav) {
  return (
    <div className="navBar">
      <div className="navLeft">
        <Menu className="navIcon" size={26} onClick={() => toggleNav()} />
      </div>
      <div className="navInventory noSelect" onClick={() => toggleInventory()}>
        Inventory
      </div>
      <div className="navRight">
        <Award className="navIcon achievementsIcon" size={26} onClick={() => toggleAchievements()}/>
        <FileText className="navIcon changeLogIcon" size={26} />
        <Settings className="navIcon settingsIcon" size={26} onClick={() => toggleSettings()} />
      </div>
    </div>
  );
}
