import './style.css';

export default function PinnedInventory() {
  return (
    <div className="pinnedInventory">
      <b>Pinned Inventory</b>
      <div>Money: $10</div>
      <div>Metal Scraps: 1</div>
      <div>Material 2: 45</div>
    </div>
  );
}
