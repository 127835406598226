import './style.css';

interface iRegionNav {
  name: string;
  idVal: string;
  activeTab: string;
  selectedRegion: CallableFunction
}

export default function RegionNav({ name, idVal, activeTab, selectedRegion }: iRegionNav) {
  return (
    <div className="region">
      <div id={idVal} className={activeTab === idVal ? "regionText noSelect " + idVal + "-active" : "regionText noSelect"} onClick={() => selectedRegion(idVal)}>
        {name}
      </div>
    </div>
  );
}
